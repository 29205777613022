import React from "react";
import theme from "theme";
import { Theme, Text, Box, Button, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Casa | Rent Bull
			</title>
			<meta name={"description"} content={"Na Rent Bull, entendemos que a viagem é tão importante quanto o destino. "} />
			<meta property={"og:title"} content={"Casa | Rent Bull"} />
			<meta property={"og:description"} content={"Na Rent Bull, entendemos que a viagem é tão importante quanto o destino. "} />
			<meta property={"og:image"} content={"https://creomfrou.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://creomfrou.com/img/3425326.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://creomfrou.com/img/3425326.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://creomfrou.com/img/3425326.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://creomfrou.com/img/3425326.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://creomfrou.com/img/3425326.png"} />
			<meta name={"msapplication-TileImage"} content={"https://creomfrou.com/img/3425326.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						padding="7px 20px 7px 20px"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						lg-width="fit-content"
					>
						<Text margin="0px 10px 0px 0px" color="#8b9197" font="--base" sm-margin="0px 0 5px 0px">
							Navegue na sua viagem com facilidade e conforto
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Rent Bull
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Na Rent Bull, entendemos que a viagem é tão importante quanto o destino. O nosso serviço de aluguer de automóveis foi concebido para lhe oferecer uma experiência perfeita e personalizada, garantindo que as suas viagens sejam agradáveis e sem complicações.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 50px 12px 50px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Saber mais
						</Button>
					</Box>
				</Box>
				<Image
					src="https://creomfrou.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://creomfrou.com/img/2.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Porquê escolher a Rent Bull?
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(3, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								color="--light"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									01
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Frota selecionada: A nossa seleção de veículos é suficientemente diversificada para se adequar a diferentes preferências, mas cuidadosamente selecionada em termos de qualidade e fiabilidade.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								color="--light"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									02
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Serviço personalizado: Orgulhamo-nos de oferecer um toque personalizado aos nossos serviços, assegurando que a sua experiência de aluguer é adaptada às suas necessidades específicas.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									03
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Preços transparentes: Com a Rent Bull, o que vê é o que recebe. Nossos preços são diretos, sem taxas ocultas.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="--color-primary"
								display="flex"
								align-items="center"
								color="--light"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									04
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								- Apoio 24 horas por dia, 7 dias por semana: A nossa equipa dedicada está sempre aqui para o ajudar, garantindo tranquilidade durante toda a sua experiência de aluguer.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});